import React, { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowCircleLeft,
  faArrowCircleRight,
  faPencil,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import DatePicker from "react-date-picker";
import { transactionAPI } from "services";
import TxnList from "./TxnList";
//import { useOnboardingStore } from "store/onboarding-store";
import useUniversalStore from "store/universalStore";
import ExpandableButton from "components/ExpandableButton";
import NewTxn from "views/forms/NewTxn";
import { EditTxn } from "views/forms";

function Home() {
  const { t } = useTranslation();
  const [date, setDate] = useState(new Date());
  //const goToNextStep = useOnboardingStore((state) => state.goToNextStep);
  const write = useUniversalStore((state) => state.write);
  const accountId = useUniversalStore((state) => state.accountId);
  const [animation, setAnimation] = useState("");
  const [hideClosed, setHideClosed] = useState(false);

  useEffect(() => {
     fetchTransactions();
  }, [date]);

  const fetchTransactions = async () => {
    var transactions = await transactionAPI.getTransactionsByDate(date);
    write({
      transactions: transactions
    });
  }

  const prevDate = async () => {
    const newDate = moment(date).subtract(1, "days").toDate();
    setDate(newDate);
    setAnimation("animate__animated animate__slideInRight fast-animation");
  };
  
  const nextDate = async () => {
    const today = new Date();
    if(date >= today) return;
    
    const newDate = moment(date).add(1, "days").toDate();
    setDate(newDate);
    setAnimation("animate__animated animate__slideInLeft fast-animation");
  };
  
  // const handleDate = (year,month,day)=>{
  //   let newDate = new Date(year,month);
  //   console.log("new Date ",newDate);
  //   setDate(newDate);
  // }

  const handleDate = (date)=>{
    setDate(date);
  }

  const handleToggle = (value)=>{
    setHideClosed(value);
  }

  return (
    <>
      <div className="container-fluid">
        <div className="row bg-app py-1">
          <div className="col-1" align="right">
            <a onClick={prevDate} className="btn btn-outline-light ">
              <FontAwesomeIcon
                icon={faArrowCircleLeft}
                size="2x"
                title="Previous Date"
              />
            </a>
          </div>
          <div className="col-2 offset-md-4 py-2 d-flex" align="center">
            <span>
            {hideClosed ? (
              <i class="bi bi-eye-fill fs-4 text-white px-2 cursor" onClick={() => handleToggle(false)}></i>
            ) : (
              <i class="bi bi-eye fs-4 text-white px-2 cursor" onClick={() => handleToggle(true)}></i>
            )}
            </span>
          
            <DatePicker
              value={date}
              onChange={(value) => setDate(value)}
              monthAriaLabel="Month"
              clearIcon={null}
              format="dd-MM-yyyy"
              maxDate={new Date()}
              maxDetail="month"
              className="datePicker"
              calendarIcon={<i className="bi bi-calendar-date text-white"></i>}
              showMonthYearPicker={false}
            />
           
          </div>
          <div className="col-1 offset-4" align="left">
            <a
              onClick={nextDate}
              className="btn btn-outline-light "
              disabled={date >= new Date()}
            >
              <FontAwesomeIcon
                icon={faArrowCircleRight}
                size="2x"
                title="Next Date"
              />
            </a>
          </div>
        </div>

        <TxnList
          currentDate={date}
          handleChangeMonth={handleDate}
          animation={animation}
          setAnimation={setAnimation}
          hideClosed={hideClosed}
        />

        <div className="row position-absolute mb-5 mx-5 bottom-0 end-0">
          <ExpandableButton
            title="New Entry"
            clickForm="addTxnDrawer"
            // onClick={(e) => goToNextStep()}
            icon={faPencil}
          />
        </div>
        
        <NewTxn handleChangeMonth = {handleDate} />
        <EditTxn />
      </div>
    </>
  );
}

export default Home;

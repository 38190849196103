import React, { useState, useEffect, useRef } from "react";
import { sms, transactionAPI, accountAPI } from "services";
import { Loader, Status } from "components";
import { formatMoney } from "utils";
import { Colors, creatableStyle } from "assets/themes";
import txnaudio from "assets/sounds/google_pay-mini.mp3";
import FilePicker from "views/FilePicker";
import Creatable from "react-select/creatable";
import useUniversalStore from "store/universalStore";
import DateTimePicker from "react-datetime-picker";

const OneTimeTxn = ({ setStatus, handleChangeMonth }) => {
  const { accounts, accountId, profile ,accountDetails, write } = useUniversalStore((state) => state);

  const initialState = {
    amount: "",
    date: new Date(),
    account_name: "",
    phone:"",
    details: "",
    attachment: { loading: false, file: null, url: "" },
    loading: false,
    status: { type: null, message: "" }
  };

  const [isKHATA, setIsKHATA] = useState(true);
  const [values, setValues] = useState(initialState);
  const [multipler, setMultipler] = useState(1);
  const [notify, setNotify] = useState(true);
  const [options, setOptions] = useState([]);
  const [fetching,setFetching] = useState(false);
  const [reRenderFileUpload, setRerenderFileUpload] = useState(false);
  const childRef = useRef();
  const MAX_TRANSFER_AMOUNT = 10000000;

  useEffect(() => {
    setupEventListeners();
    populateAccountName(accountId,accountDetails);
  }, [accountId,accountDetails]);

  useEffect(() => {
    setFetching(true);
    const sortedAccounts = accounts
      ?.filter((account) => !account.get("cancelled"))
      .sort((a, b) => {
        const nameA = a.get("name").toLowerCase();
        const nameB = b.get("name").toLowerCase();
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      });
  
    populateAccountsDropdown(sortedAccounts);
    setFetching(false)
  }, [accounts]);
  

  const setupEventListeners = () => {
    const offCanvas = document.getElementById("addTxnDrawer");
    if (!offCanvas) return;

    const handleHidden = () => {
      setValues(initialState);
      setIsKHATA(false);
      childRef.current.resetFileState();
      setRerenderFileUpload(false);
    };

    const handleShown = () => {
      setRerenderFileUpload(true);
      const amountField = document.getElementById("newtxnamount");
      if (amountField) {
        amountField.focus();
      }
      populateAccountName(accountId,accountDetails);
    };

    offCanvas.addEventListener("hidden.bs.offcanvas", handleHidden);
    offCanvas.addEventListener("shown.bs.offcanvas", handleShown);

    return () => {
      offCanvas.removeEventListener("hidden.bs.offcanvas", handleHidden);
      offCanvas.removeEventListener("shown.bs.offcanvas", handleShown);
    };
  };

  const populateAccountName = (accountId,accountDetails) => {
    setFetching(true);
    try {
      if (accountId || accountDetails) {
        setValues((prev)=>({
          ...prev,
          account_name: accountDetails.get("name"),
          phone:accountDetails.get("phone")
        }));
        setIsKHATA(true);
      }
      if (window.location.pathname === "/cashbook") {
        setIsKHATA(false)
        setValues((prev)=>({
          ...prev,
          account_name: null,
          phone:null
        }));
      }  
    } catch (error) {
      console.log("Error while fetching account name",error);
    } finally {
      setFetching(false)
    }
  };

  const populateAccountsDropdown = (_accounts) => {
    setFetching(true);
    const _options = [];
    for (var i = 0; i < _accounts.length; i++) {
      _options.push({
        value: _accounts[i].get("phone"),
        label: _accounts[i].get("name"),
      });
    }
    setOptions(_options);
    setFetching(false);
  };

  const failure = (msg) => {
    setValues((prev)=>({
      ...prev,
      errormsg: msg,
    }));
    setStatus({ type: "error", message: msg });
    setTimeout(() => {
      setValues((prev)=>({
        ...prev,
        errormsg: "",
      }));
      setStatus({ type: null, message: '' });
    }, 2500);
  };

  const success = (msg) => {
    setStatus({ type: "success", message: msg });
    setTimeout(() => {
      setStatus({ type: null, message: '' });
    }, 2500);
  };

  const amountChangeHandler = (e) => {
    e.preventDefault();
    let amountValue = e.target.value.replace(/[₹,]/g, "");
    setValues((prev) => {
      let tempValue = { ...prev };
      if (amountValue === NaN || amountValue === "") {
        tempValue.amount = 0;
        return tempValue;
      } else if (amountValue <= MAX_TRANSFER_AMOUNT) {
        tempValue.amount = parseFloat(amountValue.replace(/[^0-9.-]/g, ""));
        return tempValue;
      } else {
        failure("Enter Amount less than " + MAX_TRANSFER_AMOUNT);
        return tempValue;
      }
    });
  };

  const detailsChangeHandler = (e) => {
    e.preventDefault();
    setValues((prev)=>({
      ...prev,
      details: e.target.value,
    }));
  };


  // Handle the change when selecting contacts from the dropdown
  const accountChangeHandler = (selectedOption) => {
    if (!selectedOption || !selectedOption.label) {
      setValues((prev) => ({
        ...prev,
        account_name: "",
        phone: "",
      }));
      return;
    }

    if (selectedOption.__isNew__) {
      setValues((prev) => ({
        ...prev,
        account_name: selectedOption.label,
        phone:null,
      }));
    } else {
      setValues((prev) => ({
        ...prev,
        account_name: selectedOption.label,
        phone: selectedOption.value || "",
      }));
    }
  };


  const checkNumber =  ()=>{
    if (!values.phone) setNotify(false);
    else setNotify(true);
  }

  useEffect(() => {
    checkNumber();
  }, [values.account_name,accountId,accountDetails]);

  const onDateChange = (date) => {
    setValues((prev)=>({
      ...prev,
      date: date,
    }));
  };

  const notifyChangeHandler = async (e) => {
    if (values.account_name) {      
      if (values.phone) {
        setNotify((value) => !value);
      } else {
        failure("Account has no phone number");
      }
    } else {
      setNotify(() => false);
      failure("Select an account to notify");
    }
  };

  const submitAddTransaction = async (e) => {
    e.preventDefault();

    if (values.amount > 0 && values.amount <= MAX_TRANSFER_AMOUNT) {
      
      try {
        const result = await transactionAPI.transaction(
          values.account_name,
          values.amount * multipler,
          values.details,
          values.attachment.url,
          values.date
        );

        if(result){
          setTimeout(()=>{
            success("Transaction done successfully.");
          },500)
         
          const transactionDate = new Date(values.date);
          // const year = transactionDate.getFullYear();
          // const month = transactionDate.getMonth();
          // handleChangeMonth(year, month);
          handleChangeMonth(transactionDate);
          successaudio();
        }

        if (result.get("accountId")?.get("phone") !== "" && notify) {
          const amount = result.get("amount");
          const phone = "91" + result.get("accountId").get("phone");
          const partyName = profile?.get("businessName") ? profile.get("businessName") : profile.get("phone");

          if (result.get("amount") < 0) {
            sms.sendPaidSMS(Math.abs(amount), partyName, accountId, phone);
          } else {
            sms.sendReceiveSMS(Math.abs(amount), partyName, accountId, phone);
          }
        }

        if (accountId) {          
          write({
            transactions: await transactionAPI.getTransactionsByAccountIdWithMonth(accountId, values.date),
            accounts: await accountAPI.getAccounts()
          });
        } else {
          write({
            transactions: await transactionAPI.getTransactionsByDate(values.date)
          });
        }
      } catch (error) {
        console.log("Error while adding transaction: ", error);
        failure(error.message);
      }
    } else {
      failure("Enter an amount between 0 and 99,99,999₹.");
    }
  };

  const fileFetched = (field, value) => {
    setValues((prev) => ({ ...prev, [field]: value }));
  };

  const successaudio = async() => {
    var audio = new Audio(txnaudio);
    audio.play();
  };

  if(values.loading) return <Loader />;
  return (
    <div className="row">
      <div className="col-12">
        <form className="needs-validation" 
          onSubmit={submitAddTransaction} 
          id="newtxnform">
          <div className="row py-3">
            <div className="col-md-6">
              <label
                className="fw-bolder fs-smaller fonts-for-buttons-label"
                htmlFor="newtxnamount">
                Amount
              </label>
            </div>
            <div className="col-md-6">
              <label
                className="fw-bolder fs-smaller fonts-for-buttons-label mx-4">
                Date
              </label>
            </div>
            <div className="col-md-6">
              <input
                type="text"
                className="seventh-step form-control py-2"
                id="newtxnamount"
                placeholder="Amount"
                value={formatMoney(values.amount)}
                onChange={amountChangeHandler}
                required />
            </div>
            <div className="col-md-6">
              <DateTimePicker
                className="p-1"
                calendarIcon={<i className="bi bi-calendar-date fs-5"></i>}
                clearIcon={null}
                // minDate={}
                maxDate={new Date()}
                disableClock={true}
                format="dd-MM-y"
                onChange={onDateChange}
                value={values.date}
              />
            </div>
          </div>
          <div className="select-user-step py-3">
            <Creatable
              options={!isKHATA && options}
              value={ values.account_name ? { value: values.phone, label: values.account_name } : null}
              classNamePrefix="rs"
              isLoading={fetching}
              isClearable
              isDisabled={isKHATA}
              placeholder="Select Party Name"
              onChange={accountChangeHandler}
              styles={creatableStyle}
            />
          </div>
          <div className="py-3">
            <input
              type="text"
              className="eighth-step form-control"
              placeholder="Details"
              maxLength="50"
              value={values.details}
              onChange={detailsChangeHandler} />
          </div>
          <div className="my-3">
            {reRenderFileUpload && <FilePicker onPick={fileFetched} ref={childRef} loading={values.attachment.loading} />}
          </div>
          <div className="py-3 send-sms-step ">
            <div className="form-check form-switch">
              <input
                className="form-check-input primary p-2" 
                role="button"
                size={"lg"}
                type="checkbox"
                onChange={notifyChangeHandler}
                id="sendSMSCheckbox"
                checked={notify ? true : false} />
              <label
                className="form-check-label px-2"
                htmlFor="sendSMSCheckbox">
                Send SMS Notification
              </label>
            </div>
          </div>
          {values.errormsg ? (
            <div className="form-group text-start animate__animated animate__pulse">
              <div
                className="form-check-label alert alert-danger text-capitalized"
                id="newacerror">
                <i className="bi bi-exclamation-circle-fill"></i>{" "}
                {values.errormsg}
              </div>
            </div>
          ) : (
            <div className="py-2"></div>
          )}
          <div className="row my-3 sticky-bottom-drawer">
            <div className="col-md-6 d-grid mt-md-0">
              <button
                type="submit"
                className="btn btn-success block"
                disabled={values.attachment.loading}
                data-bs-dismiss={ values.amount > 0 && values.amount < MAX_TRANSFER_AMOUNT
                    ? "offcanvas": null}
                onClick={() => {
                  //goToNextStep();
                  setMultipler(1);
                }}
              >
                <b className="fs-5 text-uppercase"><span className="fs-4">+</span> You Received</b>
              </button>
            </div>
            <div className="col-md-6 d-grid mt-md-0">
              <button type="submit"
                className="btn btn-danger block py-3"
                disabled={values.attachment.loading}
                data-bs-dismiss={ values.amount > 0 && values.amount < MAX_TRANSFER_AMOUNT
                    ? "offcanvas" : null}
                onClick={() => {
                  //goToNextStep();
                  setMultipler(-1);
                }}>
                <b className="fs-5 text-uppercase"><span className="fs-4">-</span> You Paid</b>
              </button>
            </div>
          </div>
          <br />
          {/* <Status type={values.status.type} message={values.status.message} /> */}
        </form>
      </div>
    </div>
  );
};

export default OneTimeTxn;
import React, { useEffect, useState } from "react";
const accounting = require("accounting");
import moment from "moment";
import { FaFilePdf } from "react-icons/fa";

import { transactionAPI, accountAPI } from "services";
import { formatMoney } from "utils";

import ModalImage from "react-modal-image";
import { ConfirmationModal } from "components";
import useUniversalStore from "store/universalStore";

function Txn({ data, setLoader, monthlyDate }) {
  const transactionId = useUniversalStore((state) => state.transactionId);
  const accountId = useUniversalStore((state) => state.accountId);
  const write = useUniversalStore((state) => state.write);

  let attachment = data.get("attachment");
  const isPDF = attachment?.includes(".pdf");
  //TODO : If filename contains invalid characters, then change filename to valid filename
  const isImage = attachment?.match(/\.(jpg|jpeg|png|gif|webp)$/i);
  
  const deletetransaction = async (e) => {
    console.log("Deleting transaction:", transactionId);
    setLoader(true);
    try {
      const res = await transactionAPI.deleteTransaction(transactionId);
      console.log("deleteTransaction ", res);
      //Reset the transactionId
      write({
        transactionId: null,
        accounts: await accountAPI.getAccounts(),
        transaction: await transactionAPI.getTransactionsByAccountIdWithMonth(accountId,monthlyDate),
      });
    } catch (err) {
      console.error("deletetransaction error: ", err);
    } finally {
      setLoader(false);
    }
  };

  const editTransaction = () => {
    write({transactionId: data.id});
  }

  const cancelledView = () => {
    return (
      <>
        <div className="col-3 px-0" align="right">
          <i className="text-decoration-line-through text-muted">
            {formatMoney(data.get("amount"))}
          </i>
        </div>
        <div className="col-9">
          <div className="row">
            <div className="text-decoration-line-through text-muted col-8"
              align="left">
              {data.get("detail")}
            </div>
            <div className="text-decoration-line-through text-muted fst-italic col-4"
              align="right">
              {moment(data.get("date")).format("DD MMM")}
            </div>
          </div>
        </div>
      </>
    );
  };

  // const handleDownload = () => {
  //   const link = document.createElement('a');
  //   link.href = attachment;
  //   link.target = "_blank"
  //   link.download = 'filename.jpg'; 
  //   link.click();
  // };

  const normalView = () => {
    return (
      <div className="row">
        <div className="col-3 px-0" align="right">
          <i className="">
            {accounting.formatMoney(Math.abs(data.get("amount")), "₹", 0)}
          </i>
        </div>
        <div className="col-8">
          <div className="row">
            <div className="col-8" align="left">
              {data.get("detail")}
            </div>
            <div className="col-4" align="right">
              <b>
                <i>{moment(data.get("date")).format("DD MMM")}</i>
              </b>
            </div>
          </div>
          {/* Attachments */}
          <div className="row">
            <div className="col-12">
              {isPDF && (
                <a role="button"
                  href={attachment}
                  target="_blank"
                  style={{ cursor: "pointer" }}>
                  <FaFilePdf
                    size={50}
                    className="text-app"/>
                </a>
              )}

              {isImage && (
                <>
                  <ModalImage
                    small={attachment}
                    large={attachment}
                    hideDownload={true}
                    hideZoom={true}
                    alt={data.get("detail")}
                    className="mt-2 rounded-3"
                  />
                </>
              )}
            </div>
          </div>
        </div>
        {/* Dropdown to edit and delete the transaction */}
        <div className="col-1">
          {data.id ? (
            <a
              className="text-black btn bg-not-transparent-on-hover w-auto ms-auto"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              title="More options"
              align="right"
            >
              <span className="bi bi-three-dots-vertical"></span>
            </a>
          ) : null}
          <ul className="dropdown-menu row w-auto">
            <li className="col-12">
              <button
                className="w-100 btn btn-outline-dark rounded"
                data-bs-toggle="offcanvas"
                data-bs-target="#editTxnDrawer"
                onClick={() => {
                  editTransaction();
                }}
              >
                <span className="bi bi-pencil-fill"> Edit</span>
              </button>
            </li>
            <li className="col-12 py-1">
              <button className="w-100 btn btn-app rounded fs-6"
                data-bs-toggle="modal"
                onClick={() => {
                  write({ transactionId : data.id });
                }}
                data-bs-target="#deleteTxnInKhataModal">
                <i className="bi bi-trash"> Delete</i>
              </button>
            </li>
          </ul>
        </div>
      </div>
    );
  };

  return (
    <li className="list-group-item bg-transparent">
      <div className="row align-items-baseline">
        {data.get("cancelled") ? cancelledView() : normalView()}
        <ConfirmationModal id="deleteTxnInKhataModal" onClick={deletetransaction} />
      </div>
    </li>
  );
}

export default Txn;

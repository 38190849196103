import Parse from "./parse.service";

const Transaction = Parse.Object.extend("Transaction");
import { auth, accountAPI } from "./index";

// // Add a new Transaction
// export const transaction = async (_account, amount, detail, attachment, date) => {
//   const transaction = new Transaction();

//   if (attachment) {
//     transaction.set(
//       "attachment",
//       "https://bahikhatabills.s3.amazonaws.com/cb97925b28803a604429df0b4fb43f6f_download1.png"
//     );
//     const name = attachment.name;
//     var parseFile = new Parse.File(name, attachment);
//     const result = await parseFile.save();
//     transaction.set("attachment", result._url);
//   }

//   if (!!_account) {
//     console.log(_account);
//     const myAccount = await accountAPI.getAccountByName(_account);
//     if (myAccount) {
//       console.log("if account ",myAccount)
//       transaction.set("accountId", myAccount);
//       transaction.set("amount", amount);
//       transaction.set("detail", detail);
//       transaction.set("cancelled", false);
//       transaction.set("date", date);
//       transaction.set("userId", auth.fetchUser());
//       accountAPI.updateBalance(myAccount.id, amount);
//     } else {
//       const acc = await accountAPI.account(_account, "");
//       console.log("else ",acc)
//       if (acc) {
//         transaction.set("accountId", acc);
//         transaction.set("amount", amount);
//         transaction.set("detail", detail);
//         transaction.set("date", date);
//         transaction.set("cancelled", false);
//         transaction.set("userId", auth.fetchUser());
//         acc.set("balance", acc.get("balance") + amount);
//         await acc.save();
//       }
//     }
//   } else {
//     console.log("no account ",_account)
//     transaction.set("amount", amount);
//     transaction.set("detail", detail);
//     transaction.set("date", date);
//     transaction.set("cancelled", false);
//     transaction.set("userId", auth.fetchUser());
//   }
//   return transaction.save();
// }

  //method to rollback the transaction
  const transactionRollBack = async(transaction, myAccount, _account, currentAccountBalance, amount)=>{
    try {
      // Rollback the transaction if it was saved
      if (transaction.id) {
        await transaction.destroy();
        console.log("Rolling back transaction...");
      }
      // Rollback balance update
      if ((_account && myAccount) && currentAccountBalance !== 0) {
        const updatedAccount = await accountAPI.getAccountByName(_account);
        if (updatedAccount && updatedAccount.get("balance") !== currentAccountBalance) {
          updatedAccount.increment("balance", -amount);
          await updatedAccount.save();
        }
      }
    } catch (rollbackError) {
      console.error("Rollback failed: ", rollbackError);
    }
  }

  //add new transaction
  export const transaction = async ( _account, amount, detail, attachment, date) => {
    let currentAccountBalance = 0;
    let transactionSuccess = false;
    let myAccount = null;
    const transaction = new Transaction();

    try {
      //if account exist then fetch other wise create account
      if (_account) {
        myAccount = await accountAPI.getAccountByName(_account);

        if (!myAccount) {
          myAccount = await accountAPI.account(_account, "");
          if (!myAccount) throw new Error("New account creation failed.");
        }
        currentAccountBalance = myAccount.get("balance") || 0;
        transaction.set("accountId", myAccount);
      }

      // Set transaction details
      transaction.set("amount", amount);
      transaction.set("detail", detail);
      transaction.set("date", date);
      transaction.set("cancelled", false);
      transaction.set("userId", auth.fetchUser());
      transaction.set("attachment",attachment);

      // Perform atomic save of transaction & account balance
      if (myAccount) {
        myAccount.increment("balance", amount);
        console.log("accountblcbeforeupdate ",currentAccountBalance)
        const res = await Parse.Object.saveAll([transaction, myAccount]); // Atomic transaction save
        const accountblcupdated = res[1]?.get("balance");
        console.log("accountblcafterupdate ",accountblcupdated);
        transactionSuccess = true;
        return transaction;
      }
      return await transaction.save()
    } catch (error) {
      console.error("Transaction failed. Rolling back...", error);
      await transactionRollBack( transaction, myAccount, _account, currentAccountBalance, amount);
      if(error.code === 122){
        throw new Error("Filename contains invalid characters");
      }
      throw new Error("Transaction was not successful.")
      
    }
  };
  
  export const uploadFile = async (file) => {
    if(file){
      try {
        const name = file.name;
        var parseFile = new Parse.File(name, file);
        var result = await parseFile.save();
        return result;
      } catch (error) {
        console.error("Error uploading file:", error);
        return null;
      }
    }
  }

  export const getTransactionsByAccountIdWithMonth = async (accountId, currentDate) => {
    const startOfMonth = new Date(currentDate.getFullYear(),currentDate.getMonth(),
        1,0,0,0,0);
    const endOfMonth = new Date(currentDate.getFullYear(),currentDate.getMonth()+1,
      0,23,59,59,999);
    
    const account = await accountAPI.getAccountById(accountId);
    const query = new Parse.Query(Transaction);

    query.limit(1000000);
    // query.equalTo("userId", auth.fetchUser());
    query.equalTo("accountId", account);
    query.greaterThanOrEqualTo("date", startOfMonth);
    query.lessThanOrEqualTo("date", endOfMonth);
    query.ascending("date");
    query.exclude("userId");
    const results = await query.find();
    return results;
  }
  
  export const getTransactionsByDate = async (date) => {
    const query = new Parse.Query(Transaction);
    const firstDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1,
      23,59,59,999);
    const nextDate = new Date(date.getFullYear(),date.getMonth(),date.getDate(),
      23, 59, 60, 0);
    // query.include("accountId");
    query.limit(1000000);
  
    query.equalTo("userId", auth.fetchUser());
    query.greaterThan("date", firstDate);
    query.lessThan("date", nextDate);
    query.exclude("userId");
    return await query.find();
  }
  
  export const getAllTransactions = async() => {
    try {
      const query = new Parse.Query(Transaction);
      query.limit(1000000);
      query.ascending("date");
      query.equalTo("userId", auth.fetchUser());
      return await query.find();
    } catch (error) {
      console.error(error);
      return [];
    }
  };
  
  export const getTxnsByAccountId = async (id) => {
    const account = await accountAPI.getAccountById(id);
    const query = new Parse.Query(Transaction);
    query.limit(1000000);
    query.ascending("date");
    query.equalTo("userId", auth.fetchUser());
    query.equalTo("accountId", account);
    query.exclude("userId");
    return await query.find();
  }
  
  export const getTransactionById = async (id) => {
    const query = new Parse.Query(Transaction);
    query.equalTo("userId", auth.fetchUser());
    query.equalTo("objectId", id);
    query.limit(1);
    const results = await query.find();
    return results[0];
  }
  
  export const updateTransaction = async (id, _amount, _detail, date) => {
    const transaction = await getTransactionById(id);
    const oldamount = transaction.get("amount");
    const account_object = transaction.get("accountId");
    const diffamount = _amount - oldamount;
    transaction.set("amount", _amount);
    if (account_object) {
      const accountId = account_object.id;
      accountAPI.updateBalance(accountId, diffamount);
    }
    transaction.set("detail", _detail);
    transaction.set("date", date);
    return transaction.save();
  }
  
  export const deleteTransaction = async (id) => {
    const txn = await getTransactionById(id);
    txn.set("cancelled", true);
    const account_object = txn.get("accountId");
    if (account_object) {
      const accountId = account_object.id;
      accountAPI.updateBalance(accountId, -txn.get("amount"));
    }
    return txn.save();
  }
  
  export const sumTillDate = async (date) => {
    const query = new Parse.Query(Transaction);
    query.limit(100000);
    query.equalTo("userId", auth.fetchUser());
    query.lessThanOrEqualTo("date", date);
    query.equalTo("cancelled", false);
    query.exclude("userId");
    const results = await query.find();
    return results.reduce((sum,result) => sum +result.get("amount"), 0);
  }
  
  export const sumTillDateByAccount = async (date, id) => {
    const account = await accountAPI.getAccountById(id);
    if (!account) return 0;
    const query = new Parse.Query(Transaction);
    query.limit(1000000);
  
    query.equalTo("accountId", account);
    query.lessThanOrEqualTo("date", date);
    query.equalTo("cancelled", false);
    query.exclude("userId");
    const results = await query.find();
    if (results) {
      var sum = 0;
      results.map((result) => (sum += result.get("amount")));
      return sum;
    }
    return 0;
  }

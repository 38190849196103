import React, { useEffect, useState } from "react";
const accounting = require('accounting');
import moment from 'moment';
import ModalImage from "react-modal-image";
import { FaFilePdf } from "react-icons/fa";

function Txn(props) {
  const { data } = props;
  let attachment = data?.get("attachment");
  const isPDF = attachment?.includes(".pdf");
  //TODO : If filename contains invalid characters, then change filename to valid filename
  const isImage = attachment?.match(/\.(jpg|jpeg|png|gif|webp)$/i);

  
  const normalView = () => {
    return (
      <div className="d-flex mx-0 p-0 justify-content-center align-items-start">
        <div className="col-md-3 col-sm-3 px-0 text-end">
          <i className="fs-lg fw-bold me-3">
            {accounting.formatMoney(Math.abs(data.get("amount")), '₹', 0)}
          </i>
        </div>
        <div className="col-md-9 col-sm-9 justify-content-between ps-2">
          <div className="d-flex flex-wrap col-12">
            <div className="col-12 text-start mb-2" title={data.get("detail")}>
              <small style={{ "word-wrap": "break-word", "white-space": "normal" }}>{data.get("detail")}</small>
              <div align="right" className="fw-bold">
                <small><i >{moment(data.get("date")).format('DD MMM')}</i></small>
              </div>
            </div>
            <div align="left">
              {isImage &&
                <div className="container p-0 " >
                  <ModalImage
                    small={data.get("attachment")}
                    large={data.get("attachment")}
                    hideDownload={true}
                    hideZoom={true}
                    className="ms-0 rounded txnAttachment"
                  />
                </div>
              }
              {isPDF && (<a role="button"
                href={attachment}
                target="_blank"
                style={{ cursor: "pointer" }}>
                <FaFilePdf
                  size={50}
                  className="text-app" />
              </a>)}
            </div>
          </div>

        </div>
      </div>
    )
  }

  return (
    <li className="list-group-item bg-transparent ">
      <div className="row">
        {!data.get("cancelled") && normalView()}
      </div>
    </li>
  )
}

export default Txn;

import { useState, useEffect } from "react";
import Txn from "./Txn";
import OpenCloseBalance from "components/OpenCloseBalance";
import { transactionAPI } from "services";
import useUniversalStore from "store/universalStore";
import { Loader } from 'components';
import "animate.css/animate.min.css";

const TxnList = ({ currentDate, animation, setAnimation ,handleChangeMonth,hideClosed}) => {
    const txns = useUniversalStore((state) => state.transactions);
    const [openBalance, setOpenBalance] = useState(0);
    const [closeBalance, setCloseBalance] = useState(0);
    const [loading, setLoading] = useState(true);
    const [filteredTnx, setFilteredTnx] = useState(txns);

    useEffect(()=>{
      if (hideClosed) {
        const filtered = txns.filter((item) => !item.get("cancelled"));
        setFilteredTnx(filtered);
      } else {
        setFilteredTnx(txns);
      }
    },[txns,hideClosed])

    useEffect(() => {
        console.log("recalculate open close balance");
        calculateOpenCloseBalance();
    }, [currentDate,txns]);

    const calculateOpenCloseBalance = () => {
        const openDate = new Date(currentDate.getFullYear(),currentDate.getMonth(),
            currentDate.getDate(),0,0,0,0);
        const closeDate = new Date(currentDate.getFullYear(),currentDate.getMonth(),
            currentDate.getDate(),23,59,59,999);
        
        transactionAPI.sumTillDate(openDate).then((res) => {
            setOpenBalance(res);
        });
        transactionAPI.sumTillDate(closeDate).then((res) => {
            setCloseBalance(res);
        });
    }

    const ListHeader = ({type}) => {
      if(type === "Cash In"){
        return (
          <div className="list-group-item header bg-transparent">
              <div className="row align-baseline header">
                <div className="col-12 text-success" align="center">
                    <i className="bi bi-arrow-down-circle-fill fs-3"></i>
                    <span className="fs-3 text-uppercase px-3">
                    <b>{type}</b>
                    </span>
                </div>
              </div>
          </div>
        )
      }
      return (
        <div className="list-group-item bg-transparent">
          <div className="row align-baseline">
            <div className="col-12 text-danger" align="center">
              <i className="bi bi-arrow-up-circle-fill fs-3"></i>
              <span className="fs-3 text-uppercase px-3">
                <b>{type}</b>
              </span>
            </div>
          </div>
        </div>
      )
    }

    if(!txns) return <Loader />;

    return (
        <div className="row col-md-12">
          <div className={`row background overflow-y-scroll cashbook-scroll ${animation}`}
              onAnimationEnd={() => setAnimation("")}>
              <div className="col-6 cashin">
                <div className="list-group list-group-flush mb-5">
                  <ListHeader type="Cash In" />
                  <OpenCloseBalance type="open" sum={openBalance} />
                  {filteredTnx
                    .filter((txn) => txn.get("amount") > 0)
                    .filter((txn) => !txn.cancelled)
                    .map((txn, i) => (
                      <Txn data={txn} key={i} currentDate={currentDate} setLoading={setLoading} />
                    ))}
                </div>
              </div>
              <div className="col-6 cashout">
                <div className="list-group list-group-flush mb-5">
                  <ListHeader type="Cash Out" />
                  {filteredTnx
                    .filter((txn) => txn.get("amount") < 0)
                    .filter((txn) => !txn.cancelled)
                    .map((txn, i) => (
                      <Txn data={txn} key={i} currentDate={currentDate} setLoading={setLoading} />
                    ))}
                  <OpenCloseBalance type="close" sum={closeBalance} />
                </div>
            </div>
          </div>
        </div>
    );
};

export default TxnList;